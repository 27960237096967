import React from 'react'
import { graphql } from 'gatsby'

import { Bio } from '../components/layout/Bio'
import { Layout } from '../components/layout/Layout'
import { SEO } from '../components/seo'
import { HR } from '../components/styled/HR'
import { postExcerpt } from '../utils/postExcerpt'
import { BlogItem } from '../components/page/BlogItem'
import { PreviousNext } from '../components/generic/PreviousNext'

function BlogPostTemplate({
  data: {
    post,
    previousPost,
    nextPost,
    likes: { totalCount: likesCount },
  },
}) {
  return (
    <Layout>
      <SEO
        title={post.title}
        description={postExcerpt(post.excerpt, post.body)}
        image={`/og-images/blog/${post.slug.current}.png`}
        article
      />
      <BlogItem item={post} isSingle />
      {likesCount > 0 && (
        <p>{`${likesCount} ${likesCount === 1 ? 'like' : 'likes'}`}</p>
      )}
      <HR />
      <Bio author={post.author} />

      <PreviousNext
        previous={
          previousPost && {
            title: previousPost.title,
            link: previousPost.slug.current,
          }
        }
        next={
          nextPost && {
            title: nextPost.title,
            link: nextPost.slug.current,
          }
        }
        linkPrefix="/blog/"
      />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostTemplateQuery(
    $id: String!
    $previousId: String
    $nextId: String
    $permalink: String!
  ) {
    post: sanityPost(id: { eq: $id }) {
      ...BlogItem
      author {
        ...AuthorBio
      }
    }
    previousPost: sanityPost(id: { eq: $previousId }) {
      title
      slug {
        current
      }
    }
    nextPost: sanityPost(id: { eq: $nextId }) {
      title
      slug {
        current
      }
    }
    likes: allWebMentionEntry(
      filter: { wmTarget: { eq: $permalink }, wmProperty: { eq: "like-of" } }
    ) {
      totalCount
    }
  }
`
